@import '../../scss/configuration';

.Home {
	&-Grid {
		display: grid; 
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
		grid-template-rows: 1fr 1fr 1fr; 
		gap: 40px 20px; 
		grid-template-areas: 
		  ". . . . . . ."
		  ". . . . . . ."
		  ". . . . . . .";
		width: $default-screen-width;

		&-Item {
			display: flex;
			align-items: center;
			flex-direction: column;
			text-decoration: none;
			outline: none;

			&-Container {
				background: #FFFFFF;
				border: 1px solid #d9d9d9;
				box-shadow: 0px 7px 19px rgb(99 119 123 / 10%);
				border-radius: 25px;
				height: 130px;
				width: 130px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 15px;

				span {
					font-size: 60px;
					font-variation-settings: "FILL" 0, "wght" 600, "GRAD" 0, "opsz" 48;
					color: $color-primary;
					user-select: none;
				}
			}

			&-Title {
				font-size: 17px;
				font-weight: 500;
				color: $color-secondary;
				text-align: center;
			}

			&:hover,
			&:focus-visible {
				.Home-Grid-Item-Title {
					text-decoration: underline;
				}
			}
		}
	}
}