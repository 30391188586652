@import '../../../scss/configuration';

.Buchung {
	&-Item {
		width: 100%;
		background: #FFFFFF;
		border: 1px solid #d9d9d9;
		box-shadow: 0px 7px 19px rgb(99 119 123 / 10%);
		border-radius: 10px;
		padding: 20px;
		display: inline-block;
		text-decoration: none;
		color: black;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;

		div {
			display: flex;
			flex-direction: column;
		}

		&-Title {
			font-size: $font-size-large;
			font-weight: 600;
			color: $color-secondary;
			margin: 0;
		}
		&-Text {
			font-size: $font-size;
			font-weight: 400;
			color: #797F83;
			margin-top: 10px;
		}

		.material-symbols-rounded {
			font-size: 32px;
    		color: $color-secondary;
		}

		&:hover,
		&:focus-visible {
			outline: none;
			.Buchung-Item-Title {
				text-decoration: underline;
			}
		}
	}
}

.Link-Box-Item {
	.invoice-icon {
		position: absolute;
		top: calc((135px - 44px) / 2);
		right: 70px;
		background: #216871;
		padding: 8px;
		border-radius: 100px;
		font-size: 24px;
		border: 2px solid #216871;
		color: #ffffff;

		&--paid {
			background: #216871;
			border: 2px solid #216871;
		}
		&--unpaid {
			background: #e55812;
			border: 2px solid #e55812;
		}
		&--canceled {
			background: #e91000;
			border: 2px solid #e91000;
		}
	}
}

.DefaultProcesses-Rechnungen-TableContainer {
	width: 100%;
	border-radius: 5px;
	border: 1px solid #c4c4c4;
	overflow: auto;
}

.DefaultProcesses-Rechnungen-Table {
	width: 100%;
	border-collapse: collapse;
	border: none;
	
	tr {
		border-bottom: 1px solid #c4c4c4;

		th {
			background: #c7d4d7;
		}
	}
	tbody {
		tr {
			&:last-of-type {
				border-bottom: none;
			}
			&:nth-child(even) {
				background: #f2f2f2;
			}

			&:hover {
				background: #e6e6e6;
			}
		}
	}
	th, td {
		padding: 10px 15px;

		a {
			display: flex;
			align-content: center;
			justify-content: flex-start;
			text-decoration: none;
			color: $color-secondary;

			span {
				font-size: 25px;
				font-variation-settings: "FILL" 0, "wght" 600, "GRAD" 0, "opsz" 48;
			}

			&:focus-visible {
				outline: none;
				text-decoration: underline;
			}
		}
	}

	.invoice-icon {
		background: #216871;
		padding: 3px;
		border-radius: 100px;
		font-size: 21px;
		border: 2px solid #216871;
		color: #ffffff;

		&--paid {
			background: #216871;
			border: 2px solid #216871;
		}
		&--unpaid {
			background: #e55812;
			border: 2px solid #e55812;
		}
		&--canceled {
			background: #e91000;
			border: 2px solid #e91000;
		}
	}
}