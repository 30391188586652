@import '../../scss/configuration';

.Navigation {
	display: flex;
	align-items: center;
	width: 100%;
	height: 50px;
	background-color: $color-primary;
	box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.1);
	
	// Set Padding left and right to match the default screen width
	padding: 0 calc((100% - $default-screen-width - 100px /* 50px for each button */) / 2);

	&-ToolsButton {
		outline: none;
		border: none;
		height: 50px;
		width: 50px;
		padding: 0;
		display: inline-block;
		position: absolute;
		left: 0;
		top: 100px;
		background: $color-primary;
		color: $color-text-secondary;
		font-variation-settings: "FILL" 0, "wght" 500, "GRAD" 0, "opsz" 48;
		font-size: 25px;
		cursor: pointer;

		&:hover,
		&:focus-visible {
			background: darken($color-primary, 5%);
		}
		
		&--visible {
			background: darken($color-primary, 10%) !important;
		}
	}
	
	&-Tools {
		display: none;
		position: absolute;
		top: 100px;
		height: 50px;
		left: 50px;

		button {
			outline: none;
			border: none;
			height: 50px;
			width: 50px;
			padding: 0;
			display: inline-block;
			background: $color-primary;
			color: $color-text-secondary;
			font-variation-settings: "FILL" 0, "wght" 500, "GRAD" 0, "opsz" 48;
			font-size: 25px;
			cursor: pointer;
			float: left;

			&:hover,
			&:focus-visible {
				background: darken($color-primary, 5%);
			}
		}

		&--visible {
			display: block;
		}
	}

	&-ScrollButton {
		outline: none;
		border: none;
		height: 50px;
		width: 50px;
		font-size: 35px;
		font-weight: 100;
		background: $color-primary;
		color: $color-text-secondary;
		cursor: pointer;
		padding: 0 8px;
		transition: all .1s;
		user-select: none;

		&:first-of-type {
			box-shadow: 15px 0 17px -11px $color-secondary;
		}
		&:last-of-type {
			box-shadow: -15px 0 17px -11px $color-secondary;
		}

		&--hide {
			pointer-events: none;
			cursor: default;
			opacity: 0;
			box-shadow: none;
		}
	}
	
	&-Menu {
		width: 100%;
		overflow-x: scroll;
		overflow-y: hidden;
		white-space: nowrap;

		// Hide Scrollbar
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
    	scrollbar-width: none;  /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}

		&-Item {
			font-size: $font-size-large;
			color: $color-text-secondary;
			font-weight: 500;
			text-decoration: none;
			padding: 0;
			margin: 0 15px;
			height: 50px;
			display: inline-block;
			line-height: 50px;

			&:first-of-type {
				margin-left: 0;
			}
			&:last-of-type {
				margin-right: 0;
			}

			&:hover,
			&:focus-visible {
				text-decoration: underline;
				outline: none;
			}
		}
	}
}