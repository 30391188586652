@import '../../scss/configuration';

.ContentBox {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 7px 19px rgb(99 119 123 / 10%);
    border-radius: 10px;
    padding: 20px;
    display: inline-block;
    text-decoration: none;
    color: black;
    margin-bottom: 10px;

    p {
        margin: 0;
    }

    &--warning {
        background: #fef5f1;
        border-color: #efd7ca;
        box-shadow: 0px 7px 19px #e5581212;
    }

    &-Title {
        display: inline-block;
        font-size: $font-size-large;
        font-weight: 600;
        color: $color-secondary;
        margin: 0;
        margin-bottom: 10px;
    }
    &-Text {
        font-size: $font-size;
        font-weight: 400;
        color: #797F83;
        margin-top: 10px;
    }
}