@import '../../scss/configuration';

.ProcessInformationBanner {
    background: #FFFFFF;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 7px 19px rgba(99, 119, 123, 0.1);
    border-radius: 10px;
    width: 350px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    float: left;
    position: fixed;

    &-Image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 0;
    }

    &-Content {
        padding: 20px;

        &-Title {
            font-size: $font-size-large;
            font-weight: 600;
            color: $color-secondary;
            margin: 0;
        }
        &-Text {
            font-size: $font-size;
            font-weight: 400;
            color: $color-secondary;
            margin: 10px 0 0 0;
        }

        &-CancelButton,
        &-ActionButton {
            border: 0;
            outline: 0;
            background: white;
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            font-size: 16px;
            height: 40px;
            border-bottom: 1px solid #d9d9d9;
            cursor: pointer;
            font-family: $font-family;
            font-weight: 400;
            color: $color-secondary;
            .material-symbols-rounded {
                font-size: 28px;
            }

            &:hover,
            &:focus-visible {
                span:not(.material-symbols-rounded) {
                    text-decoration: underline;
                }
            }

            &:first-of-type {
                margin-top: 20px;
            }
        }

        &-SubmitButton {
            outline: 0;
            background: $color-secondary;
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            padding: 0;
            border: 1px solid darken($color-secondary, 10%);
            cursor: pointer;
            margin-top: 20px;
            font-family: "IBM Plex Sans", sans-serif;
            color: $color-text-secondary;
            justify-content: center;
            height: 60px;
            border-radius: 10px;
            font-size: 20px;
            font-weight: 500;
            box-shadow: 0px 11px 19px rgb(99 119 123 / 30%);

            &:hover,
            &:focus-visible {
                text-decoration: underline;
            }
        }
    }
}