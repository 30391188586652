@import '../../scss/configuration';

.Loader {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000070;
	display: flex;
	align-items: center;
    justify-content: center;
	z-index: 1001;
	cursor: progress;

	&-Container {
		background: #FFFFFF;
		border: 1px solid #d9d9d9;
		box-shadow: 0px 7px 19px rgb(99 119 123 / 55%);
		border-radius: 25px;
		width: 250px;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 20px;
		text-align: center;
		cursor: progress;

		svg {
			width: 70px;
			height: 70px;
			margin-bottom: 25px;
		}

		&-Text {
			text-align: center;
			font-size: 16px;
			font-weight: 500;
			color: $color-secondary;
			margin-top: 10px;
			user-select: none;
		}
	}
}