@import 'configuration';

* {
	box-sizing: border-box;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
	font-family: $font-family;
	background-color: $color-background-default;
}

.material-symbols-rounded {
	font-variation-settings:
	'FILL' 0,
	'wght' 400,
	'GRAD' 0,
	'opsz' 48
}

button {
	user-select: none;
}

.table-view {
	border-spacing: 0;
	width: 100%;

	tr,
	td,
	th {
		border-spacing: 0;
		padding: 0;
	}
	td,
	th {
		border-bottom: 1px solid #d9d9d9;
		height: 40px;
		padding: 7px 0;
	}
	th {
		text-align: left;
	}
	
	&-title {
		font-size: $font-size;
		font-family: $font-family;
		color: #797F83;

		&--highlighted {
			color: $color-secondary;
			font-weight: 600;
		}
	}
	&-content {
		font-size: $font-size;
		font-family: $font-family;
		color: $color-text-default;
		text-align: right;

		&--highlighted {
			color: $color-secondary;
			font-weight: 600;
		}
	}

	&--left-aligned {
		td {
			text-align: left;
		}
	}
	&--half {
		width: 50%;
	}
}

// Empty List
.empty-list,
.Empty-List {
	text-align: center;
    width: 500px;
    margin: 0 auto;
    background: white;
    border-radius: 10px;
    padding: 30px 10px;
    border: 1px solid #d9d9d9;
    outline: 2px solid #d9d9d9;
    outline-offset: 2px;

	h3 {
		margin: 0;
		font-size: 22px;
		color: $color-secondary;
		margin-bottom: 10px;
	}
	i {
		letter-spacing: 2px;
		font-family: $font-family-mono;
		font-style: normal;
		font-size: 14px;
		color: #9ca7b0;
	}
}