@import '../../../scss/configuration';

.Buchung {
	&-Item {
		width: 100%;
		background: #FFFFFF;
		border: 1px solid #d9d9d9;
		box-shadow: 0px 7px 19px rgb(99 119 123 / 10%);
		border-radius: 10px;
		padding: 20px;
		display: inline-block;
		text-decoration: none;
		color: black;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;

		div {
			display: flex;
			flex-direction: column;
		}

		&-Title {
			font-size: $font-size-large;
			font-weight: 600;
			color: $color-secondary;
			margin: 0;
		}
		&-Text {
			font-size: $font-size;
			font-weight: 400;
			color: #797F83;
			margin-top: 10px;
		}

		.material-symbols-rounded {
			font-size: 32px;
    		color: $color-secondary;
		}

		&:hover,
		&:focus-visible {
			outline: none;
			.Buchung-Item-Title {
				text-decoration: underline;
			}
		}
	}
}