@import '../../scss/configuration';

.Header {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100px;
	background-color: $color-secondary;
	
	// Set Padding left and right to match the default screen width
	padding: 0 calc((100% - $default-screen-width) / 2);

	box-shadow: 0 2px 22px 20px rgba($color-secondary, 0.2);
    z-index: 100000;
    position: relative;
	
	&-Logo {
		height: 32px;
		pointer-events: none;
		user-select: none;
	}
}

.Mini-Navigator {
	position: fixed;
	top: 130px;
	left: 0;
	width: 70px;
	height: 70px;
	border-radius: 0 10px 10px 0;
	background: #FFF;
	border: 1px solid #d9d9d9;
	box-shadow: 0px 7px 19px rgba(99, 119, 123, 0.1);
	display: block;
	overflow: hidden;
	z-index: 100;

	
	&-Item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 70px;
		height: 70px;
		text-decoration: none;
		text-align: center;
		color: $color-secondary;
		padding: 5px;
		flex-direction: column;

		small {
			font-size: 11px;
			margin-top: 3px;
			display: inline-block;
			font-weight: 300;
			opacity: .8;
		}
		
		&:not(:last-of-type) {
			border-bottom: 1px solid white;
		}
		
		span {
			font-size: 30px;
			font-variation-settings: "FILL" 0, "wght" 500, "GRAD" 0, "opsz" 48;
		}
	}
	
	&--expanded {
		height: auto;

		.Mini-Navigator-Item {
			&:not(:last-of-type) {
				border-bottom: 1px solid #d9d9d9;
			}
		}
	}
}