@import '../../scss/configuration';

.Button {
    display: inline-block;
    padding: 7px 15px;
    background: #28464b;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    font-weight: 500;
    border: 1px solid #16272a;
    font-family: $font-family;
    font-size: $font-size-small;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus-visible {
        text-decoration: underline;
    }
}