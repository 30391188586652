.Login {
	&-Content {
		width: 100%;
		height: calc(100% - 100px);
		display: flex;
		justify-content: center;
		background: #0000002b;

		.ContentBox {
			width: 500px;
			margin-top: 50px;
			height: fit-content;
		}

	}
	&-Form {
		&-Actions {
			display: flex;
			justify-content: flex-end;
			margin-top: 20px;
		}
	}
	&-Error {
		width: 100%;
		margin-bottom: 15px;
		color: #e91000;
	}
}