@import '../../scss/configuration';

.Status {
	&-Content {
		width: 100%;
		height: calc(100% - 100px);
		display: flex;
		justify-content: center;
		background: #0000002b;

		.ContentBox {
			width: 500px;
			margin-top: 50px;
			height: fit-content;
			padding: 35px;
			text-align: center;

			span {
				font-size: 58px;
				font-variation-settings: "FILL" 0, "wght" 600, "GRAD" 0, "opsz" 48;
				color: $color-primary;
			}
			h1 {
				margin: 20px 0;
				color: $color-text-default;
			}
			p {
				margin: 0 0 30px 0;
			}
		}

	}
}