@import '../../../../scss/configuration';

.FolderList {
	list-style-type: none;
	padding: 0;
	margin: 0;
	
	a {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		border-bottom: 1px solid #d9d9d9;
		min-height: 40px;
		padding: 6px 0;
		font-family: $font-family;
		font-weight: 400;
		color: $color-secondary;
		text-decoration: none;
		outline: 0;

		i {
			opacity: .7;
			margin-top: 5px;
			display: inline-block;
			font-size: 15px;
		}

		.material-symbols-rounded {
			font-size: 28px;
		}

		&:hover,
		&:focus-visible {
			span:not(.material-symbols-rounded) {
				text-decoration: underline;
			}
		}
	}
}