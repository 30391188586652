@import '../../scss/configuration';

.Content {
	position: absolute;
    top: 150px;
    left: 0;
    width: 100%;
    height: calc(100% - 150px);
    overflow: auto;

	// Set Padding left and right to match the default screen width
	padding: 30px calc((100% - $default-screen-width) / 2);

    &--no-navigation {
        height: calc(100% - 100px) !important;
        top: 100px !important;
    }
}