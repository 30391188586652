@import '../../scss/configuration';

.FormCheckbox {
	min-height: 45px;
	width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;

	&-Label {
		font-size: $font-size;
		font-family: $font-family;
		color: #797F83;
        user-select: none;
        width: 200px;
	}
	&-Container {
		width: calc(100% - 200px);
		margin-left: 20px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: $font-size;
		font-family: $font-family;
		color: $color-secondary;
		cursor: pointer;
		user-select: none;
		
		&-Input {
			background: #FFFFFF;
			border: 1px solid #d9d9d9;
			border-radius: 5px;
			height: 20px;
			padding: 0;
			width: 20px;
			outline: none;
			margin-right: 10px;

			&:not(:disabled) {
				cursor: pointer;
				&:hover {
					border-color: rgba($color-primary, 0.5);
				}
			}
	
			&:disabled {
				border: 1px solid #d9d9d9 !important;
				opacity: .8;
				cursor: not-allowed;
			}
	
			&:focus {
				box-shadow: 0 0 0 3px rgba($color-primary, 0.35);
				border-color: $color-primary;
			}

			&--checked {
				font-size: 17px;
				text-align: center;
				color: white;
				font-weight: bold;
				font-variation-settings: 'FILL' 0, 'wght' 700, 'GRAD' 0, 'opsz' 48;
				background: $color-primary;
			}
		}
	}
}