@import '../../scss/configuration';

.FormInput {
	min-height: 45px;
	width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;

	&-Label {
		font-size: $font-size;
		font-family: $font-family;
		color: #797F83;
        user-select: none;
        width: 200px;
	}
	&-Input {
		background: #FFFFFF;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
		font-size: $font-size;
		font-family: $font-family;
		height: 35px;
		padding: 0 10px;
		width: calc(100% - 200px);
		margin-left: 20px;
		outline: none;

		&--noLabel {
			width: 100% !important;
			margin: 0 !important;
		}
    
        &:not(:disabled) {
			cursor: pointer;
            &:hover {
                border-color: rgba($color-primary, 0.5);
            }
        }

        &:disabled {
            border: 1px solid #d9d9d9 !important;
            opacity: .8;
            cursor: not-allowed;
        }

		&:focus {
			box-shadow: 0 0 0 3px rgba($color-primary, 0.35);
			border-color: $color-primary;
		}
	}
}