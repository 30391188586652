@import '../../scss/configuration';

.SystemInformation {
	&-Content {
		width: 100%;
		height: calc(100% - 100px);
		display: flex;
		justify-content: center;
		overflow: auto;
		padding: 50px;

		.ContentBox {
			width: 70%;
			height: -moz-fit-content;
			height: fit-content;
			padding: 35px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			span {
				font-size: 58px;
				font-variation-settings: "FILL" 0, "wght" 600, "GRAD" 0, "opsz" 48;
				color: $color-primary;
			}
			h1 {
				margin: 20px 0;
				color: $color-text-default;
			}
			p {
				margin: 0 0 30px 0;
			}

			table {
				text-align: left;
				margin-top: 20px;
				border: none;
				border-collapse: collapse;
				width: 100%;

				td {
					padding: 10px 20px;
					border: 1px solid #e2e2e2;
					min-width: 200px;

					// If text is too long, break it
					word-break: break-all;
				}
			}
		}

	}
}