@import '../../scss/configuration';

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #16272a94;
    backdrop-filter: blur(0.7px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
    &-Container {
        overflow: auto;
        width: 600px;
        max-height: calc(100% - 150px - 150px);
        background: white;
        border-radius: 10px;

        &-Content {
            padding: 20px;
            border-bottom: 1px solid #d9d9d9;
            user-select: none;

            &-Title {
                display: inline-block;
                font-size: $font-size-large;
                font-weight: 600;
                color: $color-secondary;
                margin: 0;
                margin-bottom: 10px;
                user-select: none;
            }
        }
        &-Actions {
            background: $color-background-default;
            padding: 13px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
          
            &-ActionButton {
                background: none;
                border: none;
                outline: none;
                font-size: $font-size;
                font-family: $font-family;
                color: $color-secondary;
                cursor: pointer;
                font-weight: 500;
                user-select: none;

                &:hover,
                &:focus-visible {
                    text-decoration: underline;
                }

                &--danger,
                &--error {
                    color: $color-text-error;
                }
                &--warning {
                    color: $color-text-warning;
                }
                &--success {
                    color: $color-text-success;
                }
            }
            &-CloseButton {
                background: none;
                border: none;
                outline: none;
                font-size: $font-size;
                font-family: $font-family;
                color: $color-text-error;
                cursor: pointer;
                font-weight: 500;
                user-select: none;

                &:hover,
                &:focus-visible {
                    text-decoration: underline;
                }
            }
        }
    }
}