@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

// Font
$font-family: 'IBM Plex Sans', sans-serif;
$font-family-mono: 'IBM Plex Mono', monospace;
$font-size: 16px;
$font-size-small: 14px;
$font-size-large: 18px;
$font-size-xlarge: 24px;

// Colors
$color-primary: #2C8C99;
$color-secondary: #28464B;
$color-text-default: #12282b;
$color-text-secondary: #f1f1f1;
$color-text-error: #931621;
$color-text-warning: #E55812;
$color-text-success: #95C623;
$color-background-default: #F5F5F5;

// Screen
$default-screen-width: 1500px;